<template>
    <div class="proClassificationAdd">
        <el-affix :offset="55">
            <header>
                <span class="head-but" @click="cancle"><el-button plain :icon="ArrowLeft">返回</el-button></span>
                <span class="head-t">{{ title }}</span>
            </header>
        </el-affix>
        <div class="form">
            <el-col class="audit-title">
                <span class="text">基本信息</span>
            </el-col>
            <el-form ref="ruleForm" :model="form" :rules="rules" label-width="150px">
                <el-row>
                    <el-col :span="16">
                        <el-col :span="12">
                            <el-form-item label="问题名称：" prop="problemTitle">
                                <el-input
                                    :disabled="title === '查看问题'"
                                    placeholder="请输入"
                                    v-model="form.problemTitle"
                                ></el-input>
                            </el-form-item>
                        </el-col>
                    </el-col>
                    <el-col :span="16">
                        <el-form-item label="问题描述：" prop="problemDescription">
                            <el-input
                                :disabled="title === '查看问题'"
                                placeholder="请输入"
                                v-model="form.problemDescription"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="16">
                        <el-form-item label="发现单位：" prop="orgName" v-show="!organizationName1">
                            <el-input disabled placeholder="请输入" v-model="form.orgName"></el-input>
                        </el-form-item>
                        <el-form-item label="发现单位：" prop="orgName">
                            <el-input disabled placeholder="请输入" v-model="organizationName1"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="16">
                        <el-row>
                            <el-col :span="12">
                                <el-form-item label="创建人：" prop="createName">
                                    <el-input disabled placeholder="请输入" v-model="form.createName"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="创建时间：" prop="createTime">
                                    <el-input disabled placeholder="请输入" v-model="form.createTime"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-col>
                    <el-col :span="16">
                        <el-form-item label="问题附件：" prop="filePath" required="true">
                            <AddFile
                                :disabled="title === '查看问题'"
                                @putUrl="putUrl"
                                :fileList="form.filePath || []"
                                action="/common/upload"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="16">
                        <el-row>
                            <el-col :span="12">
                                <el-form-item label="整改时限：" prop="limitTime">
                                    <el-date-picker
                                        :readonly="title === '查看问题'"
                                        v-model="form.limitTime"
                                        type="daterange"
                                        placeholder="请选择"
                                        :size="size"
                                        value-format="YYYY-MM-DD"
                                    />
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="整改牵头单位：" prop="leadDeptName">
                                    <InputChangeUnit
                                        :showName="form.leadDeptName"
                                        :isRadio="false"
                                        :propsData="unitData"
                                        :title="title"
                                        @success="unitSuccess"
                                    />
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-col>
                    <el-col :span="16">
                        <el-form-item label="问题性质：" prop="problemNature">
                            <el-checkbox-group :disabled="title === '查看问题'" v-model="form.problemNature">
                                <el-checkbox v-for="(item, index) in cities" :key="index" :label="item.value">{{
                                    item.name
                                }}</el-checkbox>
                            </el-checkbox-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="16">
                        <el-row>
                            <el-col :span="12">
                                <el-form-item label="问题分类：" prop="problemType">
                                    <el-select :disabled="title === '查看问题'" v-model="form.problemType">
                                        <el-option
                                            v-for="(item, index) in problemTypeMap"
                                            :key="index"
                                            :label="item.label"
                                            :value="item.value"
                                        ></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="所属领域：" prop="problemNeighbourhood">
                                    <el-select :disabled="title === '查看问题'" v-model="form.problemNeighbourhood">
                                        <el-option
                                            v-for="(item, index) in problemNeighbourhoodMap"
                                            :key="index"
                                            :label="item.label"
                                            :value="item.value"
                                        ></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-col>
                    <el-col :span="16">
                        <el-col :span="12">
                            <el-form-item label="风险等级：" prop="problemRiskLevel">
                                <el-select :disabled="title === '查看问题'" v-model="form.problemRiskLevel">
                                    <el-option
                                        v-for="(item, index) in problemRiskLevelMap"
                                        :key="index"
                                        :label="item.label"
                                        :value="item.value"
                                    ></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-col>
                </el-row>
                <el-col class="audit-title">
                    <span class="text">流转记录</span>
                </el-col>
                <el-row>
                    <el-col :span="22">
                        <el-form-item label="" prop="indexTypeText">
                            <el-table :data="tableData" style="width: 100%">
                                <el-table-column prop="verifyNodeName" label="审核环节" />
                                <el-table-column prop="verifyPersonName" label="审核人" />
                                <el-table-column prop="verifyTime" label="审核时间" />
                                <el-table-column prop="remark" label="审核意见" />
                            </el-table>
                        </el-form-item>
                    </el-col>
                </el-row>
                <div v-show="title !== '查看问题'">
                    <el-col class="audit-title">
                        <span class="text">问题分类审核</span>
                    </el-col>
                    <el-row>
                        <el-col :span="16">
                            <el-form-item label="审核意见：" prop="verifyContent">
                                <el-input
                                    placeholder="请输入"
                                    v-model="form.verifyContent"
                                    :autosize="{ minRows: 2 }"
                                    resize="none"
                                    type="textarea"
                                ></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </div>
            </el-form>
        </div>
        <el-row :span="16" class="footer" v-if="title !== '查看问题'">
            <el-button @click="cancle">返 回</el-button>
            <el-button type="danger" @click="submit(ruleForm, 'back')">审核退回</el-button>
            <el-button type="primary" @click="submit(ruleForm)">审核通过</el-button>
        </el-row>
    </div>
</template>

<script setup>
import { ref, computed, watch, onMounted, defineEmits, defineProps, getCurrentInstance, provide } from 'vue';
import { ElMessage, ElMessageBox } from 'element-plus';
import InputChangeUnit from '@/components/InputChangeUnit';

import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import AddFile from '@/components/addFile.vue';
import AuditRaecords from '@/components/auditRaecords';
import TaskInfo from '@/views/problemFound/components/taskInfo';
import { formatDateTime } from '@/utils/common';
let store = useStore();
let userRole = ref(false);
const { proxy } = getCurrentInstance();
let rules = {
    problemTitle: [{ required: true, message: '请输入', trigger: 'blur' }],
    problemDescription: [{ required: true, message: '请输入', trigger: 'blur' }],
    orgName: [{ required: true, message: '请输入', trigger: 'blur' }],
    leadDeptName: [{ required: true, message: '请输入', trigger: 'blur' }],
    // createName: [{ required: true, message: '请输入', trigger: 'blur' }],
    // createTime: [{ required: true, message: '请选择', trigger: 'blur' }],
    limitTime: [{ required: true, message: '请选择', trigger: 'blur' }],
    problemNature: [{ required: true, message: '请选择', trigger: 'blur' }],
    problemType: [{ required: true, message: '请选择', trigger: 'blur' }],
    problemNeighbourhood: [{ required: true, message: '请选择', trigger: 'blur' }],
    problemRiskLevel: [{ required: true, message: '请选择', trigger: 'blur' }],
    filePath: [{ required: true, message: '请上传附件', trigger: 'blur' }],
    verifyContent: [{ required: true, message: '请输入', trigger: 'blur' }],
};

const cities = [
    {
        value: '1',
        name: '初发偶发',
    },
    {
        value: '2',
        name: '多发问题',
    },
    {
        value: '3',
        name: '突发问题',
    },
    {
        value: '4',
        name: '历史遗留',
    },
    {
        value: '5',
        name: '多跨疑难',
    },
    {
        value: '6',
        name: '一般性问题',
    },
];
let problemTypeMap = [
    {
        label: '政治安全',
        value: '1',
    },
    {
        label: '经济安全',
        value: '2',
    },
    {
        label: '社会安全',
        value: '3',
    },
    {
        label: '境外利益安全',
        value: '4',
    },
    {
        label: '统战自身建设',
        value: '5',
    },
];
let problemNeighbourhoodMap = [
    {
        label: '民主党派',
        value: '1',
    },
    {
        label: '无党派人士和党外知识分子',
        value: '2',
    },
    {
        label: '民族',
        value: '3',
    },
    {
        label: '宗教',
        value: '4',
    },
    {
        label: '非公有制经济',
        value: '5',
    },
    {
        label: '新的社会阶层',
        value: '6',
    },
    {
        label: '出国和归国留学生',
        value: '7',
    },
    {
        label: '港澳台侨',
        value: '8',
    },
];
let problemRiskLevelMap = [
    {
        label: '低',
        value: '1',
    },
    {
        label: '中',
        value: '2',
    },
    {
        label: '高',
        value: '3',
    },
];
let roles = store.state.user.roles;
function isDisables(role) {
    let res = true;
    if (title.value == '编辑指标') {
        role.forEach((e) => {
            if (roles.indexOf(e) > -1) {
                res = false;
            }
            return res;
        });
    } else {
        res = false;
    }
    return res;
}
const router = useRouter();

const options = [
    {
        value: '1',
        label: '每月',
    },
    {
        value: '2',
        label: '每季度',
    },
    {
        value: '3',
        label: '每半年',
    },
    {
        value: '4',
        label: '每年',
    },
];

let formData = ref({});
let frontIndexData = ref({});
let ruleForm = ref(null);
let showTable = ref(false); //表格展示
let allDisabled = ref(false); //查看所有input禁用和按钮不展示
let emit = defineEmits();
// let props = defineProps(['formData', 'title']);
// const { formData, title } = props;
// console.log(formData, title);
const route = useRoute();
let title = ref('');
let date = new Date();
const tab = route.query.title == '问题分类' ? 0 : 1;
const form = ref({
    writeTime: formatDateTime(new Date()),
    filePath: [],
});
console.log(form.value);
// 表单数据初始化
function init() {
    console.log(route);
    title.value = route.query.title;
    if (route.query.id) {
        form.value.problemId = route.query.id;
        getTaskById();
        getRcords();
    } else {
        form.value.createName = userInfo.value.displayName;
        form.value.createBy = userInfo.value.id;
        form.value.createTime = formatDateTime(new Date());
        form.value.orgName = userInfo.value.sysOrganization.organizationName;
        form.value.orgCode = userInfo.value.sysOrganization.externalId;
    }
    if (route.query.title == '查看') {
        allDisabled.value = true;
    }
    // type为判断条件，1审核和指标查看，2是已审核重大任务查看，3是已审核指标查看
    // if (route.query.type) {
    //     showTable.value = true;
    // }
}
let unitData = ref([]);
function unitSuccess(data) {
    unitData.value = data;
    console.log('unitSuccess', unitData.value);

    form.value.leadDeptName = data.map((item) => item.organizationName).join(',');
    form.value.leadDept = data.map((item) => item.externalId).join(',');
    form.value.majorLeadDept = data.map((item) => item.externalId)[0];
    form.value.majorLeadDeptName = data.map((item) => item.organizationName)[0];
}
const submit = (ruleForm, type) => {
    ruleForm.validate((valid, fields) => {
        console.log('valid', valid);
        if (!valid) {
            return;
        }

        let newForm = Object.assign({}, form.value);
        // bakc代表是审核退回 verifyType1退回  0通过
        if (type === 'back') {
            newForm.verifyType = 1;
        } else {
            newForm.verifyType = 0;
        }
        // 区分内网和互联网环境的上传,内网不能用oss
        if (process.env.VUE_APP_API_ENV_IRONMENT === 'intranet') {
            newForm.fileUrl = form.value.filePath.map((e) => e.url).join(',');
            newForm.fileName = form.value.filePath.map((e) => e.name).join(',');
        } else {
            newForm.attachmentKey = form.value.filePath.map((e) => e.attachmentKey).join(',');
            newForm.attachmentName = form.value.filePath.map((e) => e.attachmentName).join(',');
            newForm.attachmentSize = form.value.filePath.map((e) => e.attachmentSize).join(',');
        }
        newForm.problemNature = form.value.problemNature.join(',');
        newForm.createTime = null;
        newForm.limitBeginTime = form.value.limitTime[0];
        newForm.problemVerifyType = 1;
        newForm.limitEndTime = form.value.limitTime[1];
        proxy.$api.proClassification.verify(newForm).then((res) => {
            if (res.code == 200) {
                ElMessage.success('审核完成');

                router.push({
                    name: 'proClassification',
                    query: {
                        tab: route.query.tab,
                    },
                });
            } else {
                ElMessage.error(res.msg);
            }
        });
    });
};
const organizationName1=ref()
const getTaskById = () => {
    //根据id获取任务详情
    proxy.$api.problemFound.getById(route.query.id).then((res) => {
        if (res.code == 200) {
            form.value = res.data;
            frontIndexData.value = res.data.frontIndex;
            form.value.writeTime = res.data.writeTime;
            form.value.completeNum = res.data.completeNum;
            form.value.doneFlag = res.data.doneFlag;
            form.value.filePath = res.data.filePath;
            organizationName1.value=res.data.orgParentName+'/'+res.data.orgName;
            form.value.limitTime = [res.data.limitBeginTime, res.data.limitEndTime];
            form.value.problemNature = res.data.problemNature.split(',');
            // 设置整改牵头单位回显
            unitData.value = form.value.leadDept.split(',').map((item, index) => {
                return {
                    externalId: item,
                    organizationName: form.value.leadDeptName.split(',')[index],
                };
            });
            console.log('form.value', form.value);
        }
    });
};

function putUrl(url) {
    console.log(url, form.value.filePath);
    form.value.filePath = url;
}
let cancle = () => {
    router.push({
        name: 'proClassification',
        query: {
            tab: route.query.tab,
        },
    });
};
let tableData = ref([]);
function getRcords() {
    //根据id获取任务流转记录
    proxy.$api.problemFound.verifyGetPage(route.query.id).then((res) => {
        tableData.value = res.data.records;
    });
}
const userInfo = computed(() => {
    return store.state.user.userinfo;
});
onMounted(() => {
    console.log('userinfo', userInfo.value);
    init();
});
</script>

<style scoped lang="stylus">
.proClassificationAdd
    height: calc(100% - 32px)
    overflow: hidden
    header
        // width: 1200px;
        height: 56px;
        line-height: 56px
        text-align: center
        background: #FFFFFF;
        box-shadow: 0 0 10px -6px rgba(0,0,0,0.30);
        border-radius: 4px;
        padding-left 24px
        border-bottom: 1px solid #EAEAEA
        .head-but
            float left
        .head-t
            font-family: PingFangSC-Medium;
            font-weight: 500;
            font-size: 18px;
            color: #333333;
    // .form
    //     padding 24px 24px 25px
    //     background: #FFFFFF;
    //     // height value
    //     // height: 580px
    //     .footer
    //         text-align: center
    //         height: 64px;
    //         justify-content: center
    //         border-top 1px solid #EEEEEE
    //         align-items: center !important
    //         /deep/ .el-form-item
    //             margin-bottom 0
    //     .milestone
    //         width 100%
    //         height 100%
    .title
        text-align: left
        font-weight: 500;
        font-size: 16px;
        margin-bottom: 16px
    .audit-title
        display: flex
        justify-content: space-around
        font-family: PingFangSC-S0pxibold;
        font-size: 16px;
        color: #333333;
        .text
            width 66%
            margin-bottom 16px
            font-weight: 600;

    .add-unit
        margin-bottom 24px
        color: #1492FF;
        text-align center
    .unit-input
        position relative
    .icon
        width: 16px;
        height: 16px;
        position absolute
        top 8px
        right -25px
        background: url('~@/assets/img/delete.png') no-repeat
    .tagertNum
        /deep/ .el-form-item__content
            flex-wrap: nowrap
            justify-content: space-between
/deep/ .el-cascader
    width 39.2vw
/deep/ .el-select
    width 35vw
/deep/ .el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf
    border-bottom: 0px solid #fff
/deep/ .el-table td.el-table__cell, .el-table th.el-table__cell.is-leaf
    border-bottom: 0px solid #fff
/deep/ .el-row
    justify-content center
</style>
